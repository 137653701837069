/*--------------------------------------------------------------
# testimonial Section
--------------------------------------------------------------*/

    .testimonial.section {
        display: flex;
        flex-direction: column;
        justify-content: center; /* Vertically centers the content */
        align-items: center; /* Horizontally centers the content */
       
        text-align: center; /* Center-aligns the text */
        /* width:70% */
        padding-left: 50px;
      }
  .testimonial.section .row {
    justify-content: center; /* Centers the columns horizontally */
    align-items: center; /* Centers the columns vertically */
    flex-wrap: wrap; /* Allows items to wrap if necessary */
  }
  
  .service-item {
    text-align: left; /* Aligns text to the left within each card */
    margin: 0 auto; /* Ensures the service items are centered */
  }
.testimonial .service-item {
    background-color: #f9e3df;
    padding: 40px;
    box-shadow: 0px 0 30px rgba(0, 0, 0, 0.1);
    height: 100%;
    border-radius: 15px;
  }
  
  .testimonial .service-item .icon {
    width: 48px;
    height: 48px;
    position: relative;
    margin-bottom: 50px;
  }
/*   
  .testimonial .service-item .icon i {
    color: color-mix(in srgb, var(--heading-color), transparent 30%);
    font-size: 56px;
    transition: ease-in-out 0.3s;
    z-index: 2;
    position: relative;
  } */
  
  /* .testimonial .service-item .icon:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: color-mix(in srgb, var(--heading-color), transparent 95%);
    border-radius: 50px;
    z-index: 1;
    top: 10px;
    right: -20px;
    transition: 0.3s;
  }
   */
  .testimonial .service-item h3 {
    color: color-mix(in srgb, var(--heading-color), transparent 20%);
    font-weight: 700;
    margin: 0 0 20px 0;
    padding-bottom: 8px;
    font-size: 22px;
    position: relative;
    display: inline-block;
   
    transition: 0.3s;
  }
  
  .testimonial .service-item p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  