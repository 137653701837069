.contact-form7-contact1 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.contact-form7-max-width {
  width: 95%;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.contact-form7-section-title {
  gap: var(--dl-space-space-unit);
  width: 442px;
  height: 394px;
  align-self: center;
  align-items: flex-start;
  margin-bottom: 0px;
  justify-content: center;
}
.contact-form7-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  flex-direction: column;
}
.contact-form7-content2 {
  gap: 16px;
  width: 253px;
  height: 163px;
  display: flex;
  padding: 8px 0;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form7-row1 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
.contact-form7-row2 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
.contact-form7-row3 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
.contact-form7-form {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 633px;
}
.contact-form7-container1 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.contact-form7-input1 {
  gap: var(--dl-space-space-halfunit);
  width: 50%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form7-input2 {
  gap: var(--dl-space-space-halfunit);
  width: 50%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form7-container2 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.contact-form7-input3 {
  gap: var(--dl-space-space-halfunit);
  width: 50%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form7-input4 {
  gap: var(--dl-space-space-halfunit);
  width: 50%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form7-container3 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form7-input5 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form7-container4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form7-input6 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form7-checkbox1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
}
.contact-form7-button {
  align-self: flex-start;
  background-color: #990000;
}
.contact-form7-text20 {
  display: inline-block;
}
.contact-form7-text21 {
  display: inline-block;
}
.contact-form7-text22 {
  display: inline-block;
}
.contact-form7-text23 {
  display: inline-block;
}
.contact-form7-text24 {
  display: inline-block;
}
.contact-form7-text25 {
  display: inline-block;
}
.contact-form7-text28 {
  color: #ffffff;
  display: inline-block;
}
.contact-form7-root-class-name {
  background-color: #fff;
}
@media(max-width: 991px) {
  .contact-form7-max-width {
    align-items: flex-start;
    flex-direction: column;
  }
  .contact-form7-section-title {
    width: 100%;
  }
  .contact-form7-form {
    width: 100%;
  }
  .contact-form7-container1 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    flex-direction: row;
  }
  .contact-form7-input1 {
    width: 50%;
  }
  .contact-form7-input2 {
    width: 50%;
    flex-direction: column;
  }
  .contact-form7-container2 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    flex-direction: row;
  }
  .contact-form7-input3 {
    width: 50%;
  }
  .contact-form7-input4 {
    width: 50%;
  }
  .contact-form7-container3 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    flex-direction: row;
  }
  .contact-form7-input5 {
    gap: var(--dl-space-space-halfunit);
    width: 50%;
    flex-direction: column;
  }
  .contact-form7-container4 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    flex-direction: row;
  }
  .contact-form7-checkbox1 {
    align-self: flex-start;
  }
}
@media(max-width: 479px) {
  .contact-form7-form {
    padding: var(--dl-space-space-unit);
  }
  .contact-form7-container1 {
    align-items: stretch;
    flex-direction: column;
  }
  .contact-form7-input1 {
    width: 100%;
  }
  .contact-form7-input2 {
    width: 100%;
  }
  .contact-form7-container2 {
    align-items: stretch;
    flex-direction: column;
  }
  .contact-form7-input3 {
    width: 100%;
  }
  .contact-form7-input4 {
    width: 100%;
  }
  .contact-form7-text25 {
    text-align: center;
  }
}
