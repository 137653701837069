.about{
  width: 100%;
  position: relative;
  padding: 0 0 0 0;
  height: 85vh;
  background-image:url("/assets/img/headbg.png"); 
  background-repeat: no-repeat; 
  background-size: cover;
  /* margin-top: -20px; */
}



.about .container {
  position: relative;
  z-index: 3;
  
}

.about h2 {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 49px;
}

.about h4 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  line-height: 30px;
}

.about h2 .accent {
  color: var(--conrast-color);
}

.about p {
  color: #27284e;
  font-weight: 400;
  margin-bottom: 30px;
  font-size:18px;
}


.about .btn-get-started {
  color: #fff;
  background: var(--accent-color);
  font-family: var(--heading-font);
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 14px 40px;
  border-radius: 50px;
  transition: 0.3s;
  border: 2px solid color-mix(in srgb, var(--default-color), transparent 90%);
   }

.about .btn-get-started:hover {
  border-color: color-mix(in srgb, var(--default-color), transparent 60%);
}
@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.about .btn-watch-video {
  font-size: 20px !important;
  transition: 0.5s;
  margin-left: 25px;
  font-weight: 600;
  background-color: #000000;
  padding: 10px 25px;
  border-radius: 30px;
  color: #fff;
  animation: blink 1s infinite; /* Blinking effect */
  overflow: hidden; /* Hide the overflowing text */
  white-space: nowrap; /* Prevent text wrapping */
  max-width: 70px; /* Initial width, enough to display part of the text */
}

.about .btn-watch-video:hover {
  max-width: 200px; /* Adjust based on the full text length */
  transition: max-width 0.5s ease-in-out; /* Smooth transition */
  animation: none;
}
.btn-watch-video span {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  margin-left: 8px;
  font-size: 16px;
}

.btn-watch-video:hover span {
  visibility: visible;
  opacity: 1;
}


/* .hero .btn-watch-video {
  font-size: 16px;
  transition: 0.5s;
  margin-left: 25px;
  font-weight: 600;
  color: var(--default-color);
}

.hero .btn-watch-video i {
  color: color-mix(in srgb, var(--default-color), transparent 50%);
  font-size: 32px;
  transition: 0.3s;
  line-height: 0;
  margin-right: 8px;
}

.hero .btn-watch-video:hover i {
  color: var(--default-color);
} */

@media (max-width: 640px) {
  .about h2 {
    font-size: 36px;
  }
  .about {
    height: 120vh;
  }

  .about .btn-get-started,
  .about .btn-watch-video {
    font-size: 14px;
  }
}


