.hero-privacy-header30 {
  gap: var(--dl-space-space-twounits);
}
.hero-privacy-image {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.hero-privacy-container {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 263px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: rgba(255,255,255,0.7);
}
.hero-privacy-max-width {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  flex-direction: column;
}
.hero-privacy-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.hero-privacy-actions {
  gap: var(--dl-space-space-unit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
}
.hero-privacy-text2 {
  display: inline-block;
  text-align: center;
}
.hero-privacy-root-class-name {
  width: 100%;
}
@media(max-width: 991px) {
  .hero-privacy-text2 {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .hero-privacy-actions {
    width: 100%;
    flex-direction: column;
  }
}
