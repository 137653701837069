.faq121-faq8 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.faq121-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 95%;
  height: 387px;
  align-self: center;
  align-items: flex-start;
}
.faq121-section-title {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  height: 366px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.faq121-content {
  align-self: stretch;
  align-items: flex-start;
}
.faq121-list {
  flex: 1;
  width: 100%;
}
.faq121-faq1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
}
.faq121-trigger1 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.faq121-icons-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.faq121-icon10 {
  width: 24px;
  height: 24px;
}
.faq121-icon12 {
  width: 24px;
  height: 24px;
}
.faq121-container12 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.faq121-faq2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
}
.faq121-trigger2 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.faq121-icons-container2 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.faq121-icon14 {
  width: 24px;
  height: 24px;
}
.faq121-icon16 {
  width: 24px;
  height: 24px;
}
.faq121-container15 {
  height: 87px;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.faq121-faq3 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
}
.faq121-trigger3 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.faq121-icons-container3 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.faq121-icon18 {
  width: 24px;
  height: 24px;
}
.faq121-icon20 {
  width: 24px;
  height: 24px;
}
.faq121-container18 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.faq121-faq4 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
}
.faq121-trigger4 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.faq121-icons-container4 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.faq121-icon22 {
  width: 24px;
  height: 24px;
}
.faq121-icon24 {
  width: 24px;
  height: 24px;
}
.faq121-container21 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.faq121-text19 {
  display: inline-block;
  text-align: left;
}
.faq121-text20 {
  display: inline-block;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.faq121-text21 {
  display: inline-block;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.faq121-text22 {
  display: inline-block;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.faq121-text23 {
  display: inline-block;
  text-align: left;
}
.faq121-text24 {
  display: inline-block;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
@media(max-width: 991px) {
  .faq121-max-width {
    flex-direction: column;
  }
  .faq121-list {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .faq121-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
}
