.navbardupli-container1 {
  top: 0;
  width: 100%;
  height: auto;
  display: flex;
  z-index: 1000;
  position: sticky;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.navbardupli-navbar-interactive {
  flex: 1;
  width: 100%;
  display: flex;
  padding: 0px;
  z-index: 100;
  max-width: 100%;
  min-width: auto;
  align-self: flex-start;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: 0px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
  background-color: #fff;
}
.navbardupli-link1 {
  display: contents;
}
.navbardupli-image1 {
  width: 161px;
  height: 63px;
  margin-left: var(--dl-space-space-fourunits);
  padding-left: 0px;
  text-decoration: none;
}
.navbardupli-desktop-menu {
  flex: 1;
  display: flex;
  align-self: center;
  align-items: center;
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: row;
  justify-content: center;
}
.navbardupli-links1 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-self: center;
  margin-top: 0px;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  margin-right: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-end;
}
.navbardupli-link21 {
  text-decoration: none;
}
.navbardupli-link22 {
  text-decoration: none;
}
.navbardupli-link31 {
  text-decoration: none;
}
.navbardupli-link32 {
  text-decoration: none;
}
.navbardupli-link33 {
  text-decoration: none;
}
.navbardupli-buttons1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.navbardupli-action21 {
  display: flex;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  background-color: #900000;
}
.navbardupli-text10 {
  text-decoration: none;
}
.navbardupli-burger-menu {
  display: none;
}
.navbardupli-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbardupli-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-theme-neutral-light);
}
.navbardupli-nav {
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.navbardupli-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.navbardupli-logo {
  height: 3rem;
}
.navbardupli-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbardupli-icon12 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbardupli-links2 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
}
.navbardupli-link4-accordion {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbardupli-trigger {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.navbardupli-icon-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navbardupli-container2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navbardupli-icon14 {
  width: 24px;
  height: 24px;
}
.navbardupli-container3 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navbardupli-icon16 {
  width: 24px;
  height: 24px;
}
.navbardupli-container4 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-halfunit);
  grid-template-columns: 1fr;
}
.navbardupli-menu-item1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.navbardupli-page1-image {
  width: 50px;
  height: 50px;
}
.navbardupli-content1 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.navbardupli-menu-item2 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.navbardupli-page2-image {
  width: 50px;
  height: 50px;
}
.navbardupli-content2 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.navbardupli-menu-item3 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.navbardupli-page3-image {
  width: 50px;
  height: 50px;
}
.navbardupli-content3 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.navbardupli-menu-item4 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.navbardupli-page4-image {
  width: 50px;
  height: 50px;
}
.navbardupli-content4 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.navbardupli-buttons2 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
}
.navbardupli-icon-group {
  gap: var(--dl-space-space-twounits);
  display: flex;
}
.navbardupli-text13 {
  display: inline-block;
}
.navbardupli-text14 {
  color: rgb(251, 249, 249);
  display: inline-block;
}
.navbardupli-text15 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.navbardupli-text16 {
  display: inline-block;
  background-color: rgb(212, 0, 0);
}
.navbardupli-text17 {
  display: inline-block;
}
.navbardupli-text18 {
  display: inline-block;
}
.navbardupli-text19 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.navbardupli-text20 {
  fill: var(--dl-color-theme-neutral-dark);
  color: rgb(255, 253, 253);
  display: inline-block;
  text-align: center;
  text-decoration: none;
}
.navbardupli-text24 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.navbardupli-text25 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.navbardupli-text26 {
  display: inline-block;
  background-color: rgb(212, 0, 0);
}
.navbardupli-text27 {
  color: #c7c7d8;
  display: inline-block;
  background-color: rgb(212, 0, 0);
}
.navbardupli-text28 {
  color: var(--dl-color-theme-accent2);
  display: inline-block;
}
.navbardupli-text29 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.navbardupli-text30 {
  display: inline-block;
}
.navbardupli-text31 {
  fill: var(--dl-color-theme-secondary1);
  color: secondary1 (theme) !important;
  display: inline-block;
}
.navbardupli-text32 {
  color: var(--dl-color-theme-accent2);
  display: inline-block;
}
.navbardupli-text33 {
  display: inline-block;
  background-color: rgb(212, 0, 0);
}
.navbardupli-text34 {
  display: inline-block;
}
.navbardupli-text35 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.navbardupli-root-class-name {
  background-color: transparent;
}
.navbardupli-root-class-name1 {
  align-self: center;
  background-color: #fff;
}
.navbardupli-root-class-name2 {
  background-color: #fff;
}
@media(max-width: 991px) {
  .navbardupli-desktop-menu {
    display: none;
  }
  .navbardupli-burger-menu {
    display: flex;
  }
}
@media(max-width: 767px) {
  .navbardupli-container1 {
    width: 766px;
  }
  .navbardupli-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .navbardupli-burger-menu {
    align-items: center;
    justify-content: center;
  }
  .navbardupli-mobile-menu {
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .navbardupli-container4 {
    grid-gap: 0;
  }
}
@media(max-width: 479px) {
  .navbardupli-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .navbardupli-mobile-menu {
    padding: var(--dl-space-space-unit);
  }
}
