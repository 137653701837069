.pricing14-pricing23 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
}
.pricing14-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.pricing14-section-title {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.pricing14-content {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 800px;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing14-tabs {
  display: flex;
  align-items: flex-start;
}
.pricing14-button10 {
  gap: var(--dl-space-space-halfunit);
  color: var(--dl-color-theme-neutral-light);
  width: 120px;
  height: 60px;
  border-color: #900000;
  background-color: #900000;
  border-top-left-radius: var(--dl-radius-radius-buttonradius);
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--dl-radius-radius-buttonradius);
  border-bottom-right-radius: 0;
}
.pricing14-text103 {
  color: #ffffff;
}
.pricing14-button11 {
  gap: var(--dl-space-space-halfunit);
  width: 120px;
  height: 60px;
  border-style: solid;
  border-top-left-radius: var(--dl-radius-radius-buttonradius);
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--dl-radius-radius-buttonradius);
  border-bottom-right-radius: 0;
}
.pricing14-button12 {
  gap: var(--dl-space-space-halfunit);
  color: var(--dl-color-theme-neutral-light);
  width: 120px;
  height: 60px;
  border-top-left-radius: 0;
  border-top-right-radius: var(--dl-radius-radius-buttonradius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: var(--dl-radius-radius-buttonradius);
}
.pricing14-button13 {
  gap: var(--dl-space-space-halfunit);
  width: 120px;
  height: 60px;
  border-color: #900000;
  border-style: solid;
  border-top-left-radius: 0;
  border-top-right-radius: var(--dl-radius-radius-buttonradius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: var(--dl-radius-radius-buttonradius);
}
.pricing14-container1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  animation-name: fadeIn;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.pricing14-column1 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
}
.pricing14-price10 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing14-price11 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing14-list1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.pricing14-list-item10 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item11 {
  gap: var(--dl-space-space-unit);
  height: 21px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item12 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item13 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item14 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-button14 {
  width: 100%;
  text-decoration: none;
}
.pricing14-column2 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  background-color: var(--dl-color-theme-accent1);
}
.pricing14-price12 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing14-price13 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing14-list2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.pricing14-list-item15 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item16 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item17 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item18 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item19 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item20 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-button15 {
  width: 100%;
  border-color: #900000;
  text-decoration: none;
  background-color: #900000;
}
.pricing14-column3 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  background-color: var(--dl-color-theme-accent2);
}
.pricing14-price14 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing14-price15 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing14-list3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.pricing14-list-item21 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item22 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item23 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item24 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item25 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item26 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-button16 {
  width: 100%;
  border-color: #900000;
  text-decoration: none;
  background-color: #900000;
}
.pricing14-container2 {
  gap: 32px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  animation-name: fadeIn;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.pricing14-column4 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
}
.pricing14-price16 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing14-price17 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing14-list4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.pricing14-list-item27 {
  gap: var(--dl-space-space-unit);
  height: 25px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item28 {
  gap: var(--dl-space-space-unit);
  height: 18px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item29 {
  gap: var(--dl-space-space-unit);
  height: 25px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item30 {
  gap: var(--dl-space-space-unit);
  height: 29px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item31 {
  gap: var(--dl-space-space-unit);
  height: 31px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item32 {
  gap: var(--dl-space-space-unit);
  height: 31px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item33 {
  gap: var(--dl-space-space-unit);
  height: 31px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-button17 {
  width: 100%;
}
.pricing14-column5 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  background-color: var(--dl-color-theme-accent1);
}
.pricing14-price18 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing14-price19 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing14-list5 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.pricing14-list-item34 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item35 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item36 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item37 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item38 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item39 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item40 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item41 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-button18 {
  width: 100%;
}
.pricing14-column6 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  background-color: var(--dl-color-theme-accent2);
}
.pricing14-price20 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing14-price21 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing14-list6 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.pricing14-list-item42 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item43 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item44 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item45 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item46 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item47 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item48 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item49 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-button19 {
  width: 100%;
}
.pricing14-text165 {
  display: inline-block;
}
.pricing14-text166 {
  color: #ffffff;
  display: inline-block;
}
.pricing14-text167 {
  display: inline-block;
  font-size: 48px;
}
.pricing14-text168 {
  display: inline-block;
  font-size: 48px;
}
.pricing14-text169 {
  display: inline-block;
}
.pricing14-text170 {
  display: inline-block;
  font-weight: 600;
}
.pricing14-text171 {
  display: inline-block;
}
.pricing14-text172 {
  display: inline-block;
}
.pricing14-text173 {
  display: inline-block;
  font-size: 48px;
}
.pricing14-text174 {
  display: inline-block;
}
.pricing14-text175 {
  display: inline-block;
}
.pricing14-text176 {
  display: inline-block;
  font-weight: 600;
}
.pricing14-text177 {
  display: inline-block;
}
.pricing14-text178 {
  display: inline-block;
}
.pricing14-text179 {
  display: inline-block;
}
.pricing14-text180 {
  display: inline-block;
}
.pricing14-text181 {
  display: inline-block;
}
.pricing14-text182 {
  display: inline-block;
  font-weight: 600;
}
.pricing14-text183 {
  display: inline-block;
}
.pricing14-text184 {
  display: inline-block;
}
.pricing14-text185 {
  display: inline-block;
}
.pricing14-text189 {
  display: inline-block;
}
.pricing14-text195 {
  display: inline-block;
  font-weight: 600;
}
.pricing14-text196 {
  display: inline-block;
}
.pricing14-text197 {
  display: inline-block;
}
.pricing14-text198 {
  display: inline-block;
}
.pricing14-text199 {
  display: inline-block;
}
.pricing14-text200 {
  display: inline-block;
}
.pricing14-text201 {
  display: inline-block;
}
.pricing14-text202 {
  display: inline-block;
  font-weight: 600;
}
.pricing14-text203 {
  display: inline-block;
  font-size: 48px;
}
.pricing14-text204 {
  display: inline-block;
}
.pricing14-text205 {
  display: inline-block;
}
.pricing14-text206 {
  display: inline-block;
}
.pricing14-text207 {
  display: inline-block;
}
.pricing14-text208 {
  display: inline-block;
}
.pricing14-text209 {
  display: inline-block;
  text-align: center;
}
.pricing14-text210 {
  display: inline-block;
}
.pricing14-text211 {
  color: #ffffff;
  display: inline-block;
}
.pricing14-text212 {
  display: inline-block;
  text-align: center;
}
.pricing14-text213 {
  display: inline-block;
}
.pricing14-text214 {
  display: inline-block;
}
.pricing14-text215 {
  display: inline-block;
}
.pricing14-text216 {
  display: inline-block;
}
.pricing14-text217 {
  display: inline-block;
}
.pricing14-text218 {
  display: inline-block;
}
.pricing14-text219 {
  display: inline-block;
}
.pricing14-text220 {
  display: inline-block;
}
.pricing14-text221 {
  display: inline-block;
}
.pricing14-text222 {
  display: inline-block;
  font-size: 48px;
}
.pricing14-text223 {
  display: inline-block;
  font-size: 48px;
}
.pricing14-text224 {
  display: inline-block;
  text-align: center;
}
.pricing14-text225 {
  display: inline-block;
}
.pricing14-text226 {
  display: inline-block;
}
.pricing14-text227 {
  display: inline-block;
}
.pricing14-text228 {
  display: inline-block;
}
.pricing14-text229 {
  display: inline-block;
}
.pricing14-text230 {
  display: inline-block;
}
.pricing14-text231 {
  display: inline-block;
}
.pricing14-text232 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.pricing14-text233 {
  display: inline-block;
}

@media(max-width: 991px) {
  .pricing14-container1 {
    flex-direction: column;
  }
  .pricing14-column3 {
    width: 100%;
  }
  .pricing14-container2 {
    flex-direction: column;
  }
  .pricing14-column6 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .pricing14-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
}
