.terms-conditions-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.terms-conditions-text10 {
  fill: var(--dl-color-theme-neutral-dark);
  color: rgb(255, 253, 253);
  display: inline-block;
  text-align: center;
  text-decoration: none;
}
.terms-conditions-text14 {
  color: var(--dl-color-theme-accent2);
  display: inline-block;
}
.terms-conditions-text15 {
  display: inline-block;
  background-color: rgb(212, 0, 0);
}
.terms-conditions-text16 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.terms-conditions-text17 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.terms-conditions-text18 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.terms-conditions-text19 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.terms-conditions-text20 {
  color: rgb(251, 249, 249);
  display: inline-block;
}
.terms-conditions-text21 {
  color: var(--dl-color-theme-accent2);
  display: inline-block;
}
.terms-conditions-text22 {
  color: #c7c7d8;
  display: inline-block;
  background-color: rgb(212, 0, 0);
}
.terms-conditions-text23 {
  display: inline-block;
  background-color: rgb(212, 0, 0);
}
.terms-conditions-text24 {
  fill: var(--dl-color-theme-secondary1);
  color: secondary1 (theme) !important;
  display: inline-block;
}
.terms-conditions-text25 {
  display: inline-block;
}
.terms-conditions-text26 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.terms-conditions-text27 {
  display: inline-block;
  background-color: rgb(212, 0, 0);
}
.terms-conditions-text28 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.terms-conditions-text29 {
  display: inline-block;
}
.terms-conditions-text30 {
  display: inline-block;
}
.terms-conditions-text31 {
  display: inline-block;
}
.terms-conditions-text32 {
  display: inline-block;
}
.terms-conditions-text33 {
  display: inline-block;
  text-align: center;
}
@media(max-width: 991px) {
  .terms-conditions-text33 {
    text-align: center;
  }
}
