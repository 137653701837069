.hero {
  width: 100%;
  position: relative;
  padding: 100px 0 0 0;
  height: 85vh;
  /* background-image:url("./background.png");  */
  background-repeat: no-repeat; 
  background-size: cover;

}


.hero .container {
  position: relative;
  z-index: 3;
  
}

.hero h2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 45px;
}

.hero h2 .accent {
  color: var(--conrast-color);
}

.hero p {
  color: #27284e;
  font-weight: 400;
  margin-bottom: 30px;
  font-size:18px;
}
.d-flex {

  gap: 20px; /* Adds space between the buttons */
}
.btn-ai {
  background-color: #900; /* Button background color */
  color: #fff; /* Text color */
  padding: 10px 20px; /* Button padding */
  border-radius: 25px; /* Rounded corners */
  text-decoration: none; /* Removes underline from links */
  font-size: 15px; /* Font size */
  /* margin-top: 5px; */
  font-weight: bold; /* Makes text bold */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Adds transition for hover effects */
  /* animation: blink 1s infinite; */
}
.btn-ai:hover {
 background-color: #27284e;
  color: #fff; /* Text color */
 
}
.btn-ai span {
  animation: blink 1s infinite; /* Apply the blink animation to the text */
}
@keyframes blink {
  0%, 100% {
      opacity: 1;
  }
  50% {
      opacity: 0;
  }
}

.hero .btn-get-started {
  color: #fff;
  background: var(--accent-color);
  font-family: var(--heading-font);
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 20px;
  border-radius: 50px;
  transition: 0.3s;
  /* border: 2px solid color-mix(in srgb, var(--default-color), transparent 90%); */
   }

.hero .btn-get-started:hover {
  border-color: color-mix(in srgb, var(--default-color), transparent 60%);
}
@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.hero .btn-watch-video {
  font-size: 20px !important;
  transition: 0.5s;
  margin-left: 25px;
  font-weight: 600;
  background-color: #000000;
  padding: 10px 25px;
  border-radius: 30px;
  color: #fff;
  animation: blink 1s infinite; /* Blinking effect */
  overflow: hidden; /* Hide the overflowing text */
  white-space: nowrap; /* Prevent text wrapping */
  max-width: 70px; /* Initial width, enough to display part of the text */
}

.hero .btn-watch-video:hover {
  max-width: 200px; /* Adjust based on the full text length */
  transition: max-width 0.5s ease-in-out; /* Smooth transition */
  animation: none;
}
.btn-watch-video span {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  margin-left: 8px;
  font-size: 16px;
}

.btn-watch-video:hover span {
  visibility: visible;
  opacity: 1;
}




@media (max-width: 640px) {
  .hero h2 {
    font-size: 36px;
  }
  .hero {
    height: 120vh;
  }

  .hero .btn-get-started,
  .hero .btn-watch-video {
    font-size: 14px;
  }
}


