.faqs-container {
  width: 100%;
  height: 534px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.faqs-text1 {
  display: inline-block;
  font-style: normal;
  text-align: left;
  font-family: "Rubik";
  font-weight: 400;
}
.faqs-text2 {
  display: inline-block;
  text-align: left;
  font-family: "Rubik";
}
.faqs-text3 {
  display: inline-block;
  font-style: normal;
  text-align: center;
  font-family: "Rubik";
  font-weight: 600;
}
.faqs-text4 {
  display: inline-block;
  font-style: normal;
  text-align: center;
  font-family: "Rubik";
  font-weight: 600;
}
.faqs-text5 {
  display: inline-block;
  font-style: normal;
  text-align: center;
  font-family: "Arial";
  font-weight: 700;
}
.faqs-text6 {
  display: inline-block;
  font-style: normal;
  text-align: center;
  font-family: "Rubik";
  font-weight: 600;
}
.faqs-root-class-name {
  margin-bottom: var(--dl-space-space-sixunits);
}
