.privacy-policy-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text10 {
  fill: var(--dl-color-theme-neutral-dark);
  color: rgb(255, 253, 253);
  display: inline-block;
  text-align: center;
  text-decoration: none;
}
.privacy-policy-text14 {
  color: var(--dl-color-theme-accent2);
  display: inline-block;
}
.privacy-policy-text15 {
  display: inline-block;
  background-color: rgb(212, 0, 0);
}
.privacy-policy-text16 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.privacy-policy-text17 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.privacy-policy-text18 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.privacy-policy-text19 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.privacy-policy-text20 {
  color: rgb(251, 249, 249);
  display: inline-block;
}
.privacy-policy-text21 {
  color: var(--dl-color-theme-accent2);
  display: inline-block;
}
.privacy-policy-text22 {
  color: #c7c7d8;
  display: inline-block;
  background-color: rgb(212, 0, 0);
}
.privacy-policy-text23 {
  display: inline-block;
  background-color: rgb(212, 0, 0);
}
.privacy-policy-text24 {
  fill: var(--dl-color-theme-secondary1);
  color: secondary1 (theme) !important;
  display: inline-block;
}
.privacy-policy-text25 {
  display: inline-block;
}
.privacy-policy-text26 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.privacy-policy-text27 {
  display: inline-block;
  background-color: rgb(212, 0, 0);
}
.privacy-policy-text28 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.privacy-policy-text29 {
  display: inline-block;
}
.privacy-policy-text30 {
  display: inline-block;
}
.privacy-policy-text31 {
  display: inline-block;
}
.privacy-policy-text32 {
  display: inline-block;
}
.privacy-policy-text33 {
  display: inline-block;
  text-align: center;
}
@media(max-width: 991px) {
  .privacy-policy-text33 {
    text-align: center;
  }
}
