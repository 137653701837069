#efeatures {
.feature-section-layout349 {
    gap: var(--dl-space-space-twounits);
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
  }
  .feature-section-max-width {
    width: 95%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
  }
  .feature-section-container1 {
    gap: var(--dl-space-space-halfunit);
    display: flex;
    /* max-width: 600px; */
    align-items: center;
    margin-bottom: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .feature-section-container2 {
    width: 100%;
  }
  .feature-section-card1 {
    color: #f8e3de;
    height: auto;
    display: flex;
    z-index: 100;
    align-self: flex-start;
    transition: 0.3s;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #900;
  }
  
  .feature-section-image1 {
    width: var(--dl-size-size-medium);
    height: var(--dl-size-size-medium);
    object-fit: cover;
  }
  .feature-section-card2 {
    color: var(--dl-color-theme-neutral-light);
    height: auto;
    display: flex;
    align-self: flex-start;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #900;
  }
  .feature-section-image2 {
    width: var(--dl-size-size-medium);
    height: var(--dl-size-size-medium);
    object-fit: cover;
  }
  .feature-section-card3 {
    color: var(--dl-color-theme-neutral-light);
    height: auto;
    display: flex;
    align-self: flex-start;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #900;
  }
  .feature-section-image3 {
    width: var(--dl-size-size-medium);
    height: var(--dl-size-size-medium);
    object-fit: cover;
  }
  .feature-section-container3 {
    width: 100%;
    margin-top: var(--dl-space-space-twounits);
  }
  .feature-section-card4 {
    color: var(--dl-color-theme-neutral-light);
    height: auto;
    display: flex;
    align-self: flex-start;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #900;
  }
  .feature-section-image4 {
    width: var(--dl-size-size-medium);
    height: var(--dl-size-size-medium);
    object-fit: cover;
  }
  .feature-section-card5 {
    color: var(--dl-color-theme-neutral-light);
    height: auto;
    display: flex;
    align-self: flex-start;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #900;
  }
  .feature-section-image5 {
    width: var(--dl-size-size-medium);
    height: var(--dl-size-size-medium);
    object-fit: cover;
  }
  .feature-section-card6 {
    color: var(--dl-color-theme-neutral-light);
    height: auto;
    display: flex;
    align-self: flex-start;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #900;
  }
  .feature-section-image6 {
    width: var(--dl-size-size-medium);
    height: var(--dl-size-size-medium);
    object-fit: cover;
  }
  .feature-section-text24 {
    fill: #000;
    color: #fff;
    display: inline-block;
    font-size: 24px;
    text-align: center;
  }
  .feature-section-text25 {
    display: flex;
    text-align: center;
    color: #000000;
  }
  .feature-section-text26 {
    fill: #000;
    color: #f8e3de;
    display: inline-block;
    font-size: 24px;
    text-align: center;
  }
  .thq-section-padding {
    padding: 50px;
  }
  .feature-section-text27 {
    fill: #000;
    color: #f8e3de;
    display: inline-block;
    font-size: 24px;
    text-align: center;
  }
  .feature-section-text28 {
    display: inline-block;
    text-align: center;
    color: #fff;
  }
  .feature-section-text32 {
    fill: #000;
    color: #f8e3de;
    display: inline-block;
    font-size: 24px;
    text-align: center;
  }
  .feature-section-text33 {
    fill: #000;
    color: #f8e3de;
    display: inline-block;
    font-size: 24px;
    text-align: center;
  }
  .feature-section-text34 {
    display: inline-block;
    font-size: 18px;
    text-align: center;
    /* font-family: "Rubik"; */
  }
  .feature-section-text36 {
    /* font-family: "Rubik"; */
  }
  .feature-section-text38 {
    display: inline-block;
    text-align: center;
    color: #fff;
  }
  .feature-section-text39 {
    display: inline-block;
    text-align: center;
    color: #fff;
  }
  .feature-section-text43 {
    display: inline-block;
    text-align: center;
    color: #fff;
  }
  .feature-section-text44 {
    display: inline-block;
    text-align: center;
    color: #fff;
  }
  .feature-section-text48 {
    display: inline-block;
    text-align: center;
    color: #fff;
  }
  .feature-section-text52 {
    fill: #000;
    color: #f8e3de;
    display: inline-block;
    font-size: 24px;
    text-align: center;
  }
  .feature-section-root-class-name {
    background-color: #fff;
  }
  @media(max-width: 991px) {
    .feature-section-max-width {
      flex-direction: column;
    }
    .feature-section-container1 {
      margin-bottom: var(--dl-space-space-threeunits);
    }
  }
  @media(max-width: 767px) {
    .feature-section-container1 {
      margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
    .feature-section-card1 {
      width: 100%;
    }
    .feature-section-card2 {
      width: 100%;
    }
    .feature-section-card3 {
      width: 100%;
    }
    .feature-section-card4 {
      width: 100%;
    }
    .feature-section-card5 {
      width: 100%;
    }
    .feature-section-card6 {
      width: 100%;
    }
  }
}