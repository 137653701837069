.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: flex-start;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  justify-content: space-between;
}
.home-text100 {
  fill: var(--dl-color-theme-neutral-dark);
  color: rgb(255, 253, 253);
  display: inline-block;
  text-align: center;
  text-decoration: none;
}
.home-text104 {
  color: var(--dl-color-theme-accent2);
  display: inline-block;
}
.home-text105 {
  display: inline-block;
  background-color: rgb(212, 0, 0);
}
.home-text106 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-text107 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-text108 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-text109 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-text110 {
  color: rgb(251, 249, 249);
  display: inline-block;
}
.home-text111 {
  color: var(--dl-color-theme-accent2);
  display: inline-block;
}
.home-text112 {
  color: #c7c7d8;
  display: inline-block;
  background-color: rgb(212, 0, 0);
}
.home-text113 {
  display: inline-block;
  background-color: rgb(212, 0, 0);
}
.home-text114 {
  fill: var(--dl-color-theme-secondary1);
  color: secondary1 (theme) !important;
  display: inline-block;
}
.home-text115 {
  display: inline-block;
}
.home-text116 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.home-text117 {
  display: inline-block;
  background-color: rgb(212, 0, 0);
}
.home-text118 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.home-text119 {
  display: inline-block;
}
.home-text120 {
  display: inline-block;
}
.home-text121 {
  display: inline-block;
}
.home-text122 {
  display: inline-block;
}
.home-text123 {
  display: inline-block;
  font-size: 18px;
  margin-top: 20px;
  line-height: 1.5;
  margin-bottom: 20px;
}
.home-text125 {
  line-height: 1.5;
}
.home-text127 {
  fill: #fff;
  color: #fff;
  display: inline-block;
}
.home-text128 {
  display: inline-block;
  font-size: 32px;
}
.home-text129 {
  display: inline-block;
  font-style: normal;
  margin-top: 0px;
  font-weight: 500;
}
.home-text130 {
  width: auto;
  display: inline-block;
  margin-top: 20px;
  text-align: justify;
  margin-right: 30px;
}
.home-text134 {
  width: 519px;
  height: 103px;
  display: inline-block;
  text-align: justify;
}
.home-text135 {
  display: inline-block;
  font-style: normal;
  margin-top: 10px;
  font-weight: 500;
}
.home-text136 {
  display: inline-block;
  margin-top: 10px;
  text-align: justify;
  margin-bottom: 00px;
  padding-bottom: 0px;
}
.home-text140 {
  width: 442px;
  display: inline-block;
}
.home-text143 {
  display: inline-block;
  font-size: 18px;
  text-align: center;
  font-family: "Rubik";
}
.home-text145 {
  font-family: "Rubik";
}
.home-text147 {
  display: inline-block;
}
.home-text148 {
  fill: #000;
  color: #000;
  display: inline-block;
  font-size: 30px;
  text-align: center;
}
.home-text149 {
  fill: #000;
  color: #000;
  display: inline-block;
  font-size: 30px;
  text-align: center;
}
.home-text150 {
  fill: #000;
  color: #000;
  display: inline-block;
  font-size: 30px;
  text-align: center;
}
.home-text151 {
  fill: #000;
  color: #000;
  display: inline-block;
  font-size: 30px;
  text-align: center;
}
.home-text152 {
  fill: #000;
  color: #000;
  display: inline-block;
  font-size: 30px;
  text-align: center;
}
.home-text153 {
  fill: #000;
  color: #000;
  display: inline-block;
  font-size: 30px;
  text-align: center;
}
.home-text154 {
  display: inline-block;
  text-align: center;
}
.home-text158 {
  display: inline-block;
  text-align: center;
}
.home-text162 {
  display: inline-block;
  text-align: center;
}
.home-text163 {
  display: inline-block;
  text-align: center;
}
.home-text167 {
  display: inline-block;
  text-align: center;
}
.home-text171 {
  display: inline-block;
  text-align: center;
}
.home-text172 {
  display: inline-block;
  text-align: left;
}
.home-text176 {
  display: inline-block;
  text-align: left;
}
.home-text180 {
  display: inline-block;
  text-align: left;
}
.home-text184 {
  display: inline-block;
  text-align: left;
}
.home-text189 {
  display: inline-block;
  text-align: center;
}
.home-text190 {
  display: inline-block;
}
.home-text191 {
  display: inline-block;
}
.home-text192 {
  display: inline-block;
}
.home-text193 {
  display: inline-block;
}
.home-text194 {
  display: inline-block;
}
.home-text195 {
  display: inline-block;
}
.home-text196 {
  display: inline-block;
}
.home-text197 {
  display: inline-block;
}
.home-text198 {
  display: inline-block;
}
.home-text199 {
  display: inline-block;
  font-weight: 600;
}
.home-text200 {
  display: inline-block;
  font-weight: 600;
}
.home-text201 {
  display: inline-block;
  font-weight: 600;
}
.home-text202 {
  display: inline-block;
  font-weight: 600;
}
.home-text203 {
  display: inline-block;
  font-weight: 600;
}
.home-text204 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-text205 {
  display: inline-block;
  text-align: center;
}
.home-text206 {
  display: inline-block;
  text-align: center;
}
.home-text207 {
  display: inline-block;
  text-align: center;
}
.home-text208 {
  display: inline-block;
  font-size: 48px;
}
.home-text209 {
  display: inline-block;
  font-size: 48px;
}
.home-text210 {
  display: inline-block;
  font-size: 48px;
}
.home-text211 {
  display: inline-block;
}
.home-text212 {
  display: inline-block;
  font-size: 48px;
}
.home-text213 {
  color: #ffffff;
  display: inline-block;
}
.home-text214 {
  display: inline-block;
  font-size: 48px;
}
.home-text215 {
  color: #ffffff;
  display: inline-block;
}
.home-text216 {
  display: inline-block;
  font-size: 48px;
}
.home-text217 {
  display: inline-block;
}
.home-text218 {
  display: inline-block;
}
.home-text219 {
  display: inline-block;
}
.home-text220 {
  display: inline-block;
}
.home-text221 {
  display: inline-block;
}
.home-text227 {
  display: inline-block;
}
.home-text228 {
  display: inline-block;
}
.home-text229 {
  display: inline-block;
}
.home-text230 {
  display: inline-block;
}
.home-text231 {
  display: inline-block;
}
.home-text232 {
  display: inline-block;
}
.home-text233 {
  display: inline-block;
}
.home-text234 {
  display: inline-block;
}
.home-text235 {
  display: inline-block;
}
.home-text236 {
  display: inline-block;
}
.home-text237 {
  display: inline-block;
}
.home-text241 {
  display: inline-block;
}
.home-text242 {
  display: inline-block;
}
.home-text243 {
  display: inline-block;
}
.home-text244 {
  display: inline-block;
}
.home-text245 {
  display: inline-block;
}
.home-text246 {
  display: inline-block;
}
.home-text247 {
  display: inline-block;
}
.home-text248 {
  display: inline-block;
}
.home-text249 {
  display: inline-block;
}
.home-text250 {
  display: inline-block;
}
.home-text251 {
  display: inline-block;
}
.home-text252 {
  display: inline-block;
}
.home-text253 {
  display: inline-block;
}
.home-text254 {
  display: inline-block;
}
.home-text255 {
  display: inline-block;
}
.home-text256 {
  display: inline-block;
}
.home-text257 {
  display: inline-block;
}
.home-text258 {
  display: inline-block;
}
.home-text259 {
  display: inline-block;
}
.home-text260 {
  display: inline-block;
}
.home-text261 {
  display: inline-block;
}
.home-text262 {
  display: inline-block;
}
.home-text263 {
  display: inline-block;
}
.home-text264 {
  display: inline-block;
}
.home-text265 {
  display: inline-block;
}
.home-text266 {
  display: inline-block;
}
.home-text267 {
  display: inline-block;
}
.home-text268 {
  display: inline-block;
}
.home-text269 {
  display: inline-block;
  font-size: 18px;
}
.home-text270 {
  display: inline-block;
  font-size: 23px;
  margin-top: -5px;
}
