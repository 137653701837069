.demo-section {
    width: 100%;
    padding: 50px;
    background-color: #f9f9f9;
    box-sizing: border-box;
    margin: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.demo-section h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
}

.demo-section p {
    font-size: 1.2em;
    color: #666;
    margin-bottom: 40px;
}

/* Upload Section Styling */
.upload-section {
    border: 2px dashed #900;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    display: inline-block;
}

.upload-section h3 {
    font-size: 1.5em;
    color: #900;
    margin-bottom: 10px;
}

input[type="file"] {
    padding: 10px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #900;
}

/* Analysis Section Styling */
.analysis-section {
    margin: 30px 0;
}

.analysis-section h3 {
    font-size: 1.5em;
    color: #333;
}

.analysis-section p {
    font-size: 1.1em;
    color: #900;
    margin-bottom: 20px;
}

.progress-bar {
    width: 100%;
    height: 20px;
    background-color: #ddd;
    margin-top: 20px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
}

.progress-bar-fill {
    width: 0;
    height: 100%;
    background-color: #900;
    animation: loadProgress 3s forwards;
}

@keyframes loadProgress {
    to {
        width: 100%;
    }
}

/* Result Section Styling */
.result-section {
    margin: 30px 0;
}

.result-section h3 {
    font-size: 1.5em;
    color: #333;
}

.error-message {
    color: #ff4d4f;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    padding: 10px;
    background-color: #f8d7da;
    border-radius: 5px;
}

/* Download Section Styling */
.download-section {
    margin: 30px 0;
}

.download-section h3 {
    font-size: 1.5em;
    color: #333;
}

button {
    padding: 10px 20px;
    font-size: 1em;
    background-color: #900;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 30px;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #ff4d4f;
}

/* Reset Section Styling */
.reset-section {
    margin: 30px 0;
}

.reset-section h3 {
    font-size: 1.5em;
    color: #333;
}
