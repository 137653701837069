.hero-section-dupli-container1 {
    width: 100%;
    height: 700px;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
    background-size: cover;
    justify-content: flex-start;
    background-image: url("https://itground.net/images/bg1.png");
    background-size: cover;
    background-position: top;
  }
  .hero-section-dupli-container2 {
    width: 95%;
    height: 617px;
    display: flex;
    align-self: center;
    align-items: flex-start;
    justify-content: center;
  }
  .hero-section-dupli-container3 {
    flex: 0 0 auto;
    width: 80%;
    height: 506px;
    display: flex;
    align-self: flex-start;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .hero-section-dupli-buttons {
    gap: var(--dl-space-space-twounits);
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .hero-section-dupli-action2 {
    fill: #fff;
    color: #fff;
    display: flex;
    margin-top: -10px;
    border: none;
    border-color: #900000;
    padding-right: var(--dl-space-space-twounits);
    flex-direction: row;
    background-color: #900000;
  }
  .hero-section-dupli-text2 {
    text-decoration: none;
  }
  .hero-section-dupli-container4 {
    flex: 0 0 auto;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  /* .hero-section-dupli-image {
    width: 100%;
    height: 523px;
    object-fit: cover;
  } */
  .hero-section-dupli-text3 {
    fill: #fff;
    color: #fff;
    display: inline-block;
  }
  .hero-section-dupli-text4 {
    display: inline-block;
    font-size: 42px;
    color: #fff;
  }
  .hero-section-dupli-text5 {
    display: inline-block;
    font-size: 18px;
    margin-top: 20px;
    line-height: 1.5;
    margin-bottom: 20px;
    color: #fff;
  }
  .hero-section-dupli-text7 {
    line-height: 1.5;
  }
  
  