.contact-form-container {
  width: 100%;
  height: 624px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  background-color: #fff;
}
.contact-form-text1 {
  display: inline-block;
  font-family: "Rubik";
}
.contact-form-text2 {
  display: inline-block;
  font-family: "Rubik";
}
.contact-form-text3 {
  color: #ffffff;
  display: inline-block;
}
.contact-form-text4 {
  display: inline-block;
  font-family: "Rubik";
}
.contact-form-text5 {
  display: inline-block;
  font-family: "Rubik";
}
.contact-form-text8 {
  display: inline-block;
  font-family: "Rubik";
}
.contact-form-text9 {
  display: inline-block;
  font-family: "Rubik";
}
.contact-form-root-class-name {
  background-color: #fff;
}
@media(max-width: 479px) {
  .contact-form-text5 {
    text-align: center;
  }
}
