
  /*--------------------------------------------------------------
  # Global Header
  --------------------------------------------------------------*/
  .header {
    --background-color: #ffffff;
    --heading-color: #000;
    color: var(--default-color);
    transition: all 0.5s;
    z-index: 997;
    background-color: #ffffff;
    
  }

  .header .logo img {
    
    margin-left: 50px;
    z-index: 0;
    /* width: 120px; */
  }
  
  
  .header .topbar {
    background-color: color-mix(in srgb, var(--accent-color) 0%, black 100%);
    height: 40px;
    padding: 0;
    font-size: 14px;
    transition: all 0.5s;
  }
  
  .header .topbar .contact-info i {
    font-style: normal;
    color: var(--contrast-color);
  }
  
  .header .topbar .contact-info i a,
  .header .topbar .contact-info i span {
    padding-left: 5px;
    color: var(--contrast-color);
  }


  
  @media (max-width: 575px) {
  
    .header .topbar .contact-info i a,
    .header .topbar .contact-info i span {
      font-size: 13px;
    }
  }
  
  .header .topbar .contact-info i a {
    line-height: 0;
    transition: 0.3s;
  }
  
  .header .topbar .contact-info i a:hover {
    color: var(--contrast-color);
    text-decoration: underline;
  }
  
  .header .topbar .social-links a {
    color: color-mix(in srgb, var(--contrast-color), transparent 40%);
    line-height: 0;
    transition: 0.3s;
    margin-left: 20px;
  }
  
  .header .topbar .social-links a:hover {
    color: var(--contrast-color);
  }
  
  .header .branding {
    max-height: 80px;
    padding: 10px 0;
  }
  
  .header .logo {
    line-height: 1;
    
  }
  
  .header .logo img {
    
    margin-left: 50px;
    z-index: 0;
    /* width: 120px; */
  }
  
  .header .logo h1 {
    font-size: 30px;
    margin: 0;
    font-weight: 700;
    color: var(--heading-color);
  }
  
  .header .logo span {
    font-size: 32px;
    margin-left: 2px;
    color: var(--nav-hover-color);
  }
  
  .scrolled .header {
    box-shadow: 0px 0 18px rgba(0, 0, 0, 0.1);
  }
  
  /* Sign In button styling */

.navmenu .nav-signin a {
  background-color: #900000; /* Background color */
  color: #fff; /* Text color */
  padding: 10px 20px; /* Padding for the button */
  border-radius: 20px; /* Rounded corners */
  transition: all 0.3s ease-in-out; /* Smooth transition for hover effects */
  
}

.navmenu .nav-signin a:hover {
  color: #fff; /* Hover text color */
  background-color: #000; /* Optionally change background color on hover */
  
}


 /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /* Desktop Navigation */
  @media (min-width: 1200px) {
    .navmenu {
      padding: 0;
    }
  
    .navmenu ul {
      margin: 0;
      padding: 0;
      display: flex;
      list-style: none;
      align-items: center;
    }
  
    .navmenu li {
      position: relative;
    }
  
    .navmenu>ul>li {
      white-space: nowrap;
      padding: 15px 14px;
    }
  
    .navmenu>ul>li:last-child {
      padding-right: 0;
    }
  
    .navmenu a,
    .navmenu a:focus {
      color: #000;
      font-size: 15px;
      padding: 0 2px;
      font-family: var(--nav-font);
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      transition: 0.3s;
      position: relative;
    }
  
    .navmenu a i,
    .navmenu a:focus i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
      transition: 0.3s;
    }
  
    .navmenu>ul>li>a:before {
      content: "";
      position: absolute;
      height: 2px;
      bottom: -6px;
      left: 0;
      background-color: var(--nav-hover-color);
      visibility: hidden;
      width: 0px;
      transition: all 0.3s ease-in-out 0s;
    }
  
    .navmenu a:hover:before,
    .navmenu li:hover>a:before,
    .navmenu .active:before {
      visibility: visible;
      width: 100%;
      
    }
  
    .navmenu li:hover>a,
    .navmenu .active,
    .navmenu .active:focus {
      /* color: var(--nav-color); */
      color:#900000;
    }
  
    .navmenu .dropdown ul {
      margin: 0;
      padding: 10px 0;
      background: var(--nav-dropdown-background-color);
      display: block;
      position: absolute;
      visibility: hidden;
      left: 14px;
      top: 130%;
      opacity: 0;
      transition: 0.3s;
      border-radius: 4px;
      z-index: 99;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    }
  
    .navmenu .dropdown ul li {
      min-width: 200px;
    }
  
    .navmenu .dropdown ul a {
      padding: 10px 20px;
      font-size: 15px;
      text-transform: none;
      color: var(--nav-dropdown-color);
    }
  
    .navmenu .dropdown ul a i {
      font-size: 12px;
    }
  
    .navmenu .dropdown ul a:hover,
    .navmenu .dropdown ul .active:hover,
    .navmenu .dropdown ul li:hover>a {
      color: var(--nav-dropdown-hover-color);
    }
  
    .navmenu .dropdown:hover>ul {
      opacity: 1;
      top: 100%;
      visibility: visible;
    }
  
    .navmenu .dropdown .dropdown ul {
      top: 0;
      left: -90%;
      visibility: hidden;
    }
  
    .navmenu .dropdown .dropdown:hover>ul {
      opacity: 1;
      top: 0;
      left: -100%;
      visibility: visible;
    }
  }
  
  /* Mobile Navigation */
  @media (max-width: 1199px) {
    .mobile-nav-toggle {
      color: #000;
      font-size: 28px;
      line-height: 0;
      margin-right: 10px;
      cursor: pointer;
      transition: color 0.3s;
    }
  
    .navmenu {
      padding: 0;
      z-index: 9997;
    }
  
    .navmenu ul {
      display: none;
      list-style: none;
      position: absolute;
      inset: 60px 20px 20px 20px;
      padding: 10px 0;
      margin: 0;
      border-radius: 6px;
      background-color: var(--nav-mobile-background-color);
      border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
      box-shadow: none;
      overflow-y: auto;
      transition: 0.3s;
      z-index: 9998;
    }
  
    .navmenu a,
    .navmenu a:focus {
      /* color: var(--nav-dropdown-color); */
      padding: 10px 20px;
      font-family: var(--nav-font);
      font-size: 17px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      transition: 0.3s;
    }
  
    .navmenu a i,
    .navmenu a:focus i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: 0.3s;
      background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
    }
  
    .navmenu a i:hover,
    .navmenu a:focus i:hover {
      background-color: var(--accent-color);
      color: #000;
    }
  
    .navmenu a:hover,
    .navmenu .active,
    .navmenu .active:focus {
      color: #000;
    }
  
    .navmenu .active i,
    .navmenu .active:focus i {
      background-color: var(--accent-color);
      color: var(--contrast-color);
      transform: rotate(180deg);
    }
  
    .navmenu .dropdown ul {
      position: static;
      display: none;
      z-index: 99;
      padding: 10px 0;
      margin: 10px 20px;
      background-color: var(--nav-dropdown-background-color);
      transition: all 0.5s ease-in-out;
    }
  
    .navmenu .dropdown ul ul {
      background-color: rgba(33, 37, 41, 0.1);
    }
  
    .navmenu .dropdown>.dropdown-active {
      display: block;
      background-color: rgba(33, 37, 41, 0.03);
    }
  
    .mobile-nav-active {
      overflow: hidden;
    }
  
    .mobile-nav-active .mobile-nav-toggle {
      color: #fff;
      position: absolute;
      font-size: 32px;
      top: 15px;
      right: 15px;
      margin-right: 0;
      z-index: 9999;
    }
  
    .mobile-nav-active .navmenu {
      position: fixed;
      overflow: hidden;
      inset: 0;
      background: rgba(33, 37, 41, 0.8);
      transition: 0.3s;
    }
  
    .mobile-nav-active .navmenu>ul {
      display: block;
    }
  }
  
    