#work .thq-card {
  padding: 10px;
  display:block;
  pointer-events: none;
}
.testimonial17-max-width {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .testimonial17-container {
    gap: var(--dl-space-space-unit);
    display: flex;
    max-width: 600px;
    align-items: center;
    margin-bottom: var(--dl-space-space-fourunits);
    flex-direction: column;
  }
  .testimonial17-container02 {
    gap: var(--dl-space-space-unit);
    display: flex;
    align-self: flex-start;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .testimonial17-image {
    width: 80px;
    height: 80px;
    object-fit: contain;
    border-radius: var(--dl-radius-radius-round);
  }
  .testimonial17-container03 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
  .testimonial17-container04 {
    gap: var(--dl-space-space-unit);
    display: flex;
    align-self: flex-start;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .testimonial17-image1 {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: var(--dl-radius-radius-round);
  }
  .testimonial17-container05 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
  .testimonial17-container06 {
    gap: var(--dl-space-space-unit);
    display: flex;
    align-self: flex-start;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .testimonial17-image2 {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: var(--dl-radius-radius-round);
  }
  .testimonial17-container07 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
  .testimonial17-container08 {
    gap: var(--dl-space-space-unit);
    display: flex;
    align-self: flex-start;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .testimonial17-image3 {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: var(--dl-radius-radius-round);
  }
  .testimonial17-container09 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
  .testimonial17-text14 {
    display: inline-block;
  }
  .testimonial17-text15 {
    display: inline-block;
  }
  .testimonial17-text16 {
    display: inline-block;
    text-align: center;
  }
  .testimonial17-text17 {
    display: inline-block;
    text-align: left;
  }
  .testimonial17-text22 {
    display: inline-block;
    text-align: left;
  }
  .testimonial17-text26 {
    display: inline-block;
  }
  .testimonial17-text27 {
    display: inline-block;
    text-align: left;
  }
  .testimonial17-text31 {
    display: inline-block;
  }
  .testimonial17-text32 {
    display: inline-block;
  }
  .testimonial17-text33 {
    display: inline-block;
  }
  .testimonial17-text34 {
    display: inline-block;
    text-align: left;
  }
  .testimonial17-text38 {
    display: inline-block;
  }
  .testimonial17-text39 {
    display: inline-block;
  }
  .testimonial17-text40 {
    display: inline-block;
  }
  @media(max-width: 991px) {
    .testimonial17-container {
      margin-bottom: var(--dl-space-space-threeunits);
    }
  }
  @media(max-width: 767px) {
    .testimonial17-container {
      margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
    .testimonial17-card {
      width: 80%;
    }
    .testimonial17-card1 {
      width: 80%;
    }
    .testimonial17-card2 {
      width: 80%;
    }
    .testimonial17-card3 {
      width: 80%;
    }
  }
  