.body-privacy-faq8 {
  width: 1154px;
  height: 3553px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  padding-bottom: 0px;
}
.body-privacy-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 1227px;
  height: 3520px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.body-privacy-container1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.body-privacy-section-title {
  width: 100%;
  align-self: center;
  align-items: center;
}
.body-privacy-container2 {
  width: 1140px;
  height: 3478px;
}
.body-privacy-container3 {
  display: contents;
}
.body-privacy-content1 {
  align-self: center;
}
.body-privacy-content2 {
  gap: 16px;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-self: stretch;
  flex-direction: column;
}
.body-privacy-root-class-name {
  width: 100%;
  position: 0px;
  align-self: center;
}
@media(max-width: 991px) {
  .body-privacy-container1 {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .body-privacy-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
}
