.footer {
  color: var(--default-color);
  background-color: #d3d3d3;
  font-size: 14px;
  position: relative;
  padding: 0px 0;
  width:100%;
  margin-top: 30px;
}

.footer .footer-top {
  padding-top: 50px;
  padding-bottom: 30px;
}

.footer .footer-about .logo {
  line-height: 1;
  margin-bottom: 20px;
}

.footer .footer-about .logo img {
  max-height: 90px;
  width:150px;
  margin-right: 6px;
  align-items: center;
}

.footer .footer-about p {
  font-size: 14px;
  font-family: var(--heading-font);
  margin-bottom: 20px;
}

.footer h4 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--heading-color);
}

.footer .footer-links ul,
.footer .footer-contact ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-links ul li,
.footer .footer-contact ul li {
  padding: 8px 0;
}

.footer .footer-links ul a,
.footer .footer-contact ul a {
  color: #27284e;
  text-decoration: none;
  transition: color 0.3s;
}

.footer .footer-links ul a:hover,
.footer .footer-contact ul a:hover {
  color: var(--default-color);
}

.footer .footer-subscribe p {
  font-size: 14px;
  margin-bottom: 20px;
  font-family: var(--heading-font);
}

.footer .footer-subscribe form {
  display: flex;
  flex-direction: column;
}

.footer .footer-subscribe input[type="email"] {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 50%);
  border-radius: 4px;
  font-size: 14px;
}

.footer .footer-subscribe button {
  padding: 10px;
  border: none;
  background-color: #900000;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.footer .footer-subscribe button:hover {
  background-color: #000;
}

.footer .social-links {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.footer .social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 50%);
  font-size: 16px;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
  transition: 0.3s;
}

.footer .social-links a:hover {
  color: var(--default-color);
  border-color: var(--default-color);
}

.footer .social-icon {
  max-width: 40px;
  transition: transform 0.3s;
}

.footer .social-icon:hover {
  transform: scale(1.1);
}

.footer .footer-contact p {
  margin-bottom: 5px;
  font-size: 14px;
  font-family: var(--heading-font);
}

.footer .copyright {
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  text-align: center;
  font-size: 14px;
}

.footer .copyright p {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .footer .footer-top .row {
    flex-direction: column;
    gap: 30px;
  }
  .footer-about .logo {
    flex-direction: column;
    
  }
  .footer .footer-about,
  .footer .footer-links,
  .footer .footer-contact,
  .footer .footer-subscribe{
    text-align: center;
  }
  .footer .social-links{
    justify-content: center;
  }
  .footer .footer-subscribe form {
    align-items: center;
  }

  .footer .footer-subscribe input[type="email"] {
    width: 80%;
  }

  .footer .footer-subscribe button {
    width: 80%;
  }
}
.footer .social-links a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 50%);
  font-size: 20px; /* Adjust font size for the icons */
  color: #900000;
  margin-right: 10px;
  transition: 0.3s;
}

.footer .social-links a:hover {
  color: #fff;
  border-color: var(--default-color);
}
